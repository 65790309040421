import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { SIDEBAR_WIDTH_CONDENSED } from '../../layout.model';
import { Employee } from '../../../pages/admin/setup/employees/employees.model';
import { EmployeesService } from '../../../pages/admin/setup/employees/employees.service';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss'],

})
export class LeftsidebarComponent implements OnInit {

  @Input() sidebarType: string;

  currentUser: Employee;
  greeting: any;
  user: any;
  imgSrc: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private employeesService: EmployeesService
    ) { }

  ngOnInit() {
      this.user = this.authenticationService.currentUserValue;

      const today = new Date();
      const curHr = today.getHours();

      if (curHr < 12) {
        this.greeting = 'Good Morning';
      } else if (curHr < 18) {
        this.greeting = 'Good Afternoon';
      } else {
        this.greeting = 'Good Evening';
      }

      this.loadProfile(this.user._id)
  }

  private loadProfile(id: any) {
    this.employeesService.getEmployeeById(id)
    .subscribe((data: any) => {
      this.user = data;
      if (this.user.photo) {
        this.imgSrc =  "https://elasticbeanstalk-ap-southeast-1-384417455062.s3-ap-southeast-1.amazonaws.com/employee/" + this.user.photo;
      } else {
        this.imgSrc = (this.user.gender[0].name === 'MALE') ? 'assets/images/users/male.jpg' : 'assets/images/users/female.jpg';
      }
      
    });
  }

  /**
   * Is sidebar condensed?
   */
  isSidebarCondensed() {
    return this.sidebarType === SIDEBAR_WIDTH_CONDENSED;
  }

  /**
   * Logout the user
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: '/' } });
  }
}
