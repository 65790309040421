import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear: any;

  constructor(
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    var date = new Date();
    this.currentYear = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy');
  }

} 
