import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class ConfirmDialogComponent {
    title: string;
    prompt: string;

    constructor(public activeModal: NgbActiveModal) {
    }
  }
