import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

import { Employee } from '../../pages/admin/setup/employees/employees.model';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<Employee>;
    public currentUser: Observable<Employee>;
    private token: string;

    constructor(
      private http: HttpClient,
      private router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject<Employee>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    /**
     * Returns the current user
     */
    public get currentUserValue(): Employee {
        return this.currentUserSubject.value;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email, password) {
      return this.http.post<any>(`${environment.apiUrl}/setup/employees/authenticate`, { email, password })
          .pipe(map(employee => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(employee));
              this.currentUserSubject.next(employee);
              const token = employee.token;
              return employee;
          }));
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        this.token = null;
        this.currentUserSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    reset(email) {
        return this.http.post<any>(`${environment.apiUrl}/setup/employees/reset`, { email });
    }
}

