import { MenuItem } from './menu.model';

export const MENUVN: MenuItem[] = [
    {
        label: 'Navigation',
        isTitle: true
    },
    {
        label: 'Dashboard',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'home',
        link: '/'
    },
    {
        label: 'Setup',
        dept: ['ADM', 'ITD'],
        role: ['ADM'],
        icon: 'settings',
        subItems: [
            {
                label: 'Branches',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/branches'
            },
            {
                label: 'Departments',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/departments'
            },
            {
                label: 'Positions',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/positions'
            },
            {
                label: 'Vehicles',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/vehicles'
            },
            {
                label: 'Employees',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/employees'
            },
            {
                label: 'Employee Attendances',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/employee-attendances'
            },
            {
                label: 'Employee Overtimes',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/employee-overtimes'
            },
            {
                label: 'Employee Mileages',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/employee-mileages'
            },
            {
                label: 'Employee Outstation Claims',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/employee-outstation-claims'
            },
            {
                label: 'Employee Leaves',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/employee-leaves'
            },
            {
                label: 'Manual Apply Leaves',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/manual-leaves-add'
            },
            {
                label: 'Holidays',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/setup/holidays'
            },
        ]
    },
    {
        label: 'Configurators Setup',
        dept: ['ADM', 'ITD'],
        role: ['ADM'],
        icon: 'sliders',
        subItems: [
            {
                label: 'Conveyor',
                subItems: [
                    {
                        label: 'Conveyor Options',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/conveyor/conveyor-options'
                    },
                    {
                        label: 'Conveyor Criterias',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/conveyor/conveyor-criterias'
                    },
                    {
                        label: 'Conveyor Types',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/conveyor/conveyor-types'
                    }
                ]
            },
            {
                label: 'Electrical',
                subItems: [
                    {
                        label: 'Electrical Options',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/electrical/electrical-options'
                    },
                    {
                        label: 'Electrical Criterias',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/electrical/electrical-criterias'
                    },
                    {
                        label: 'Electrical Types',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/electrical/electrical-types'
                    }
                ]
            },
            {
                label: 'Packaging',
                subItems: [
                    {
                        label: 'Packaging Options',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/packaging/packaging-options'
                    },
                    {
                        label: 'Packaging Criterias',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/packaging/packaging-criterias'
                    },
                    {
                        label: 'Packaging Types',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/packaging/packaging-types'
                    }
                ]
            },
            {
                label: 'Robotic',
                subItems: [
                    {
                        label: 'Robotic Options',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/robotic/robotic-options'
                    },
                    {
                        label: 'Robotic Criterias',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/robotic/robotic-criterias'
                    },
                    {
                        label: 'Robotic Types',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/robotic/robotic-types'
                    }
                ]
            },
            {
                label: 'Weigh',
                subItems: [
                    {
                        label: 'Weigh Options',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/weigh/weigh-options'
                    },
                    {
                        label: 'Weigh Criterias',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/weigh/weigh-criterias'
                    },
                    {
                        label: 'Weigh Types',
                        dept: ['ADM', 'ITD'],
                        role: ['ADM'],
                        link: '/configurators/weigh/weigh-types'
                    }
                ]
            }
        ]
    },
    {
        label: 'CRM Setup',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD'],
        icon: 'users',
        subItems: [
            {
                label: 'Visit Purposes',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/crm-setup/visit-purposes'
            },
            {
                label: 'Industry Types',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM'],
                link: '/crm-setup/industry-types'
            },
            {
                label: 'Company Types',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM'],
                link: '/crm-setup/company-types'
            },
            {
                label: 'Quotation Statuses',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/crm-setup/quotation-statuses'
            },
            {
                label: 'Project Statuses',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/crm-setup/project-statuses'
            },
            {
                label: 'VO Statuses',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/crm-setup/vo-statuses'
            },
            {
                label: 'Project Types',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM'],
                link: '/crm-setup/project-types'
            },
            {
                label: 'Payment Terms',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM'],
                link: '/crm-setup/payment-terms'
            },
            {
                label: 'Customers',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/crm-setup/customers'
            },
            {
                label: 'CRM Customers',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/crm-setup/crm-customers'
            },
            {
                label: 'Items',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/crm-setup/items'
            },
            {
                label: 'Products',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM'],
                link: '/crm-setup/products'
            },
            {
                label: 'Conversions',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM'],
                link: '/crm-setup/conversions'
            },
            {
                label: 'Projects',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM'],
                link: '/crm-setup/projects'
            },
            {
                label: 'Tradings',
                dept: ['ADM', 'ITD'],
                role: ['ADM'],
                link: '/crm-setup/tradings'
            }
        ]
    },
    {
        label: 'Configurators',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'file-text',
        subItems: [
            {
                label: 'My Configurators',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/quotations'
            }
        ]
    },
    {
        label: 'CRM',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'book',
        subItems: [
            {
                label: 'My Sales Target',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/sales-targets'
            },
            {
                label: 'My Customers',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/crm-customers'
            },
            {
                label: 'My Lead Customers',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/lead-customers'
            },
            {
                label: 'My Visit Reports',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/visit-reports'
            },
            {
                label: 'My Tasks',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/tasks'
            },
            {
                label: 'My Quotations',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/quotations-confirm'
            },
            {
                label: 'My VOs',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/vos-confirm'
            },
            {
                label: 'Subordinate Calendar',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/crm/calendar-subordinate'
            },
            {
                label: 'Subordinate Quotations',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/crm/quotations-confirm-subordinate'
            },
            {
                label: 'Supervise Quotations',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP'],
                link: '/crm/quotations-confirm-supervise'
            },
            {
                label: 'Subordinate VOs',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/crm/vos-confirm-subordinate'
            },
            {
                label: 'Supervise Customers',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP'],
                link: '/crm/supervise-customers'
            },
        ]
    },
    {
        label: 'CRM Analysis',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'bar-chart-2',
        subItems: [
            {
                label: 'Quotation Individual',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/analysis/quotation-individual-summary'
            },
            {
                label: 'Quotation Group',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/analysis/quotation-group-summary'
            },
            {
                label: 'Visit Report Individual',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/analysis/visit-report-individual'
            },
            {
                label: 'Visit Report Group',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/crm/analysis/visit-report-group'
            },
            {
                label: 'Employee Summary',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/crm/analysis/employees-summary'
            },
            {
                label: 'Sales Summary',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM'],
                link: '/crm/analysis/sales-summary'
            },
        ]
    },
    {
        label: 'Projects',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'paperclip',
        subItems: [
            {
                label: 'Projects Intake',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP'],
                link: '/projects/projects-intake'
            },
            {
                label: 'Vos Intake',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP'],
                link: '/projects/vos-intake'
            },
            {
                label: 'Projects P&L',
                dept: ['ADM', 'ITD', 'SKL', 'SJB', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/projects/projects-pnl'
            },
            {
                label: 'Projects Schedule',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/projects/projects-schedule'
            },
            {
                label: 'Projects Mileage',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/projects/projects-mileage'
            },
            {
                label: 'Projects Overtimes',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/projects/projects-overtimes'
            },
            {
                label: 'Projects Hour',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/projects/projects-hour'
            },
            {
                label: 'Pre-Sales Hour',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/projects/presales-hour'
            },
            {
                label: 'Services Hour',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/projects/services-hour'
            },
            
            {
                label: 'Projects Analysis',
                dept: ['ADM', 'ITD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/projects/projects-analysis'
            }
            
        ]
    },
    {
        label: 'Leaves',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'clipboard',
        subItems: [
            {
                label: 'My Leaves',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/leaves'
            },
            {
                label: 'Subordinate Leaves',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/leaves-subordinate'
            }
        ]
    },
    {
        label: 'Overtimes',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'clock',
        subItems: [
            {
                label: 'My Overtimes',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/overtimes'
            },
            {
                label: 'Subordinate Overtimes',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/overtimes-subordinate'
            }
        ]
    },
    {
        label: 'Mileages',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'map',
        subItems: [
            {
                label: 'My Mileages',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/mileages'
            },
            {
                label: 'Subordinate Mileages',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD'],
                link: '/mileages-subordinate'
            }
        ]
    },
    {
        label: 'Claims',
        dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
        role: ['ADM', 'HOD', 'SUP', 'STA'],
        icon: 'dollar-sign',
        subItems: [
            {
                label: 'Outstation Claims',
                dept: ['ADM', 'EED', 'ITD', 'MED', 'SKL', 'SJB', 'STD', 'WHD', 'SDV'],
                role: ['ADM', 'HOD', 'SUP', 'STA'],
                link: '/outstation-claims'
            }
        ]
    }
];

