import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';

import { Employee } from './employees.model';


@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  constructor(
    private http: HttpClient
  ) { }

    listEmployees(): Observable<Employee[]> {
      return this.http.get<Employee[]>(`${environment.apiUrl}/setup/employees`)
      .pipe(
          tap(_ => this.log('Fetched employees')),
          catchError(this.handleError('listEmployees', []))
      );
    }

    listEmployeeFilter(status: any): Observable<Employee[]> {
      return this.http.post<Employee[]>(`${environment.apiUrl}/setup/employees/filter`, status)
      .pipe(
          tap(_ => this.log('Fetched employees')),
          catchError(this.handleError('listEmployees', []))
      );
    }

    listActiveEmployees(): Observable<Employee[]> {
      return this.http.get<Employee[]>(`${environment.apiUrl}/setup/employees/active`)
      .pipe(
          tap(_ => this.log('Fetched active employees')),
          catchError(this.handleError('listActiveEmployees', []))
      );
    }

    listSalesEmployees(): Observable<Employee[]> {
      return this.http.get<Employee[]>(`${environment.apiUrl}/setup/employees/sales`)
      .pipe(
          tap(_ => this.log('Fetched sales employees')),
          catchError(this.handleError('listSalesEmployees', []))
      );
    }

    listMechanicalEmployees(): Observable<Employee[]> {
      return this.http.get<Employee[]>(`${environment.apiUrl}/setup/employees/mechanical`)
      .pipe(
          tap(_ => this.log('Fetched mechanical employees')),
          catchError(this.handleError('listMechanicalEmployees', []))
      );
    }

    listSubordinates(id: any): Observable<Employee[]> {
      return this.http.get<Employee[]>(`${environment.apiUrl}/setup/employees/subordinate/${id}`)
      .pipe(
          tap(_ => this.log('Fetched sales employees')),
          catchError(this.handleError('listSalesEmployees', []))
      );
    }

    listDistrictRoleEmployees(district: any, role: any): Observable<Employee[]> {
      return this.http.get<Employee[]>(`${environment.apiUrl}/setup/employees/${district}/${role}`)
      .pipe(
          tap(_ => this.log('Fetched employees')),
          catchError(this.handleError('listDistrictRoleEmployees', []))
      );
    }

    listSuperviseEmployees(id: any): Observable<Employee[]> {
      return this.http.get<Employee[]>(`${environment.apiUrl}/setup/employees/list/supervise/${id}`)
      .pipe(
          tap(_ => this.log('Fetched supervise employees')),
          catchError(this.handleError('listSuperviseEmployees', []))
      );
    }

    getEmployeeById(id: any): Observable<Employee> {
      return this.http.get<Employee>(`${environment.apiUrl}/setup/employees/${id}`)
      .pipe(
          tap(_ => this.log(`Fetched employee id: ${id}`))
      );
    }

    addEmployee(employee: Employee) {
        return this.http.post(`${environment.apiUrl}/setup/employees/create`, employee);
    }

    updateEmployee(id: any, employee: Employee) {
      return this.http.put(`${environment.apiUrl}/setup/employees/${id}`, employee)
      .pipe(
          tap(_ => this.log(`Updated employee id=${id}`))
      );
    }

    deleteEmployee(id: any) {
      return this.http.delete(`${environment.apiUrl}/setup/employees/${id}`);
    }

    updatePassword(id: any, employee: Employee) {
      return this.http.put(`${environment.apiUrl}/setup/employees/password/${id}`, employee)
      .pipe(
          tap(_ => this.log(`Updated employee id=${id}`))
      );
    }

    updatePhoto(id: any, image: any) {
      const data = {
        image: image
      }
      return this.http.put(`${environment.apiUrl}/setup/employees/photo/${id}`, data)
      .pipe(
          tap(_ => this.log(`Updated employee id=${id}`))
      );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

        console.error(error); // log to console instead
        this.log(`${operation} failed: ${error.message}`);

        return of(result as T);
        };
    }

    private log(message: string) {
        console.log(message);
    }
}
